import React, { useState, useEffect } from "react"
import WelcomeScreen from "./components/WelcomeScreen"
import QuestionAnswerPair from "./components/QuestionAnswerPair"
import Confetti from "react-confetti"
import { nanoid } from "nanoid"

export default function App() {
    const [showWelcome, setShowWelcome] = useState(true)
    const [loading, setLoading] = useState(true)
    const [gameOver, setGameOver] = useState(false)
    const [triviaData, setTriviaData] = useState([])
    const [questionAnswerPairs, setQuestionAnswerPairs] = useState([])
    const [submittedAnswers, setSubmittedAnswers] = useState({})
    const [score, setScore] = useState(0)

    useEffect(() => {
        async function fetchTriviaData() {
            await fetch("https://opentdb.com/api.php?amount=5&category=11&difficulty=easy&type=multiple")
                .then(res => res.json())
                .then(data => setTriviaData(data.results))
            setLoading(false)
        }
        fetchTriviaData()
    }, [gameOver])

    function prepareTriviaData() {
        setQuestionAnswerPairs(triviaData.map(
            eachPair => {
                const answerPosition = Math.floor(Math.random() * 4)
                const answerArray = eachPair.incorrect_answers.slice()
                answerArray.splice(answerPosition, 0, eachPair.correct_answer)
                return (
                    {
                        id: nanoid(),
                        question: eachPair.question,
                        answers: answerArray,
                        answerPosition: answerPosition,
                    }
                )
            }
        ))
    }

    function startNewGame() {
        prepareTriviaData()
        setShowWelcome(false)
        setGameOver(false)
        setSubmittedAnswers({})
        setScore(0)
    }

    function handleAnswer(e) {
        setSubmittedAnswers(prevAnswer => {
            return (
                { ...prevAnswer, [e.target.id.slice(0, -1)]: e.target.value }
            )
        })
    }

    function checkAnswers() {
        questionAnswerPairs.forEach(
            eachPair => {
                if (Number(submittedAnswers[eachPair.id]) === eachPair.answerPosition) {
                    setScore(prevScore => prevScore + 1)
                }
            }
        )
        setGameOver(true)
    }

    const questionAnswerPairElements = questionAnswerPairs.map(
        eachPair => {
            return (
                <QuestionAnswerPair
                    key={eachPair.id}
                    id={eachPair.id}
                    question={eachPair.question}
                    answers={eachPair.answers}
                    answerPosition={eachPair.answerPosition}
                    decodeHtml={decodeHtml}
                    handleAnswer={handleAnswer}
                    submittedAnswers={submittedAnswers}
                    gameOver={gameOver}
                />
            )
        }
    )

    function decodeHtml(html) {
        var txt = document.createElement("textarea")
        txt.innerHTML = html
        return txt.value
    }

    return (
        <div className="background">
            {showWelcome &&
                <WelcomeScreen
                    loading={loading}
                    startNewGame={startNewGame}
                />
            }
            <main>
                {gameOver && Object.keys(submittedAnswers).length > 0 && <Confetti />}
                <div>
                    {questionAnswerPairElements}
                </div>
                {!showWelcome &&
                    <div className="button-container">
                        <button onClick={gameOver ? startNewGame : checkAnswers}>
                            {gameOver
                                ? `You scored ${score}/5. Click to play again!`
                                : "Check answers"}
                        </button>
                    </div>
                }

            </main>
        </div>
    )
}