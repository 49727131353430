import React from "react"

export default function WelcomeScreen(props) {
    return (
        <div className="welcome-container">
            <h1 className="welcome-title">Movie Trivia</h1>
            <p className="welcome-description">Enjoy the game!</p>
            <button 
                onClick={props.loading ? null : props.startNewGame} 
                className="welcome-button">
                {props.loading ? "Loading..." : "Start quiz"}
            </button>
        </div>
    )
}