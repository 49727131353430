import React from "react"

export default function QuestionAnswerPair(props) {    
    const answerElements = props.answers.map((answer, index) => {
        let classname = ""
        if (index === props.answerPosition) {
            classname = "correct-answer"
        } else if (Number(props.submittedAnswers[props.id]) === index) {
            classname = "selected-answer"
        }
        
        return (
            <li className="answer" key={index}>
                <input 
                    type="radio" 
                    value={index}
                    id={props.id + index}
                    name={props.question} 
                    defaultChecked={false}
                    onChange={props.handleAnswer} 
                />
                <p className="answer-label">
                    <label
                        className={props.gameOver ? classname : ""} 
                        for={props.id + index}>{props.decodeHtml(answer)}
                    </label>
                </p>
            </li>
        )
    })
    
    return (
        <div>
            <p className="question">{props.decodeHtml(props.question)}</p>
            <ul className="answers">{answerElements}</ul>
        </div>
    )
}